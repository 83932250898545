import { h } from 'preact'
import { Input } from '@onfido/castor-react'
import { ID_TYPES } from '../IdTypeSelector'

export type IdNumberInputProps = {
  name?: string
  country?: string | undefined
  idType?: string | undefined
  placeholder?: string
  style?: { [key: string]: string | number }
  value?: number | string
  maxLength?: number
  invalid?: boolean
  onChange?: (ev: { target: { value: string } }) => void
}

export const IdNumberInput = (props: IdNumberInputProps) => {
  const initIdNumberInput = () => {
    props.placeholder = ''
    props.maxLength = 20

    switch (props.country) {
      case 'ARG':
        props.placeholder = '10563145'
        props.maxLength = 8
        break
      case 'AUS':
        if (props.idType === ID_TYPES.PASSPORT) {
          props.placeholder = 'PA0000000'
          props.maxLength = 9
        } else if (props.idType === ID_TYPES.DRIVING_LICENSE) {
          props.placeholder = '01P34U5L7'
          props.maxLength = 9
        } else if (props.idType === ID_TYPES.OTHER) {
          props.placeholder = ''
          props.maxLength = 20
        }
        break
      case 'BRA':
        if (props.idType === ID_TYPES.TAX_ID) {
          props.placeholder = '000.000.000-00'
          props.maxLength = 14
        } else if (props.idType === ID_TYPES.OTHER) {
          props.placeholder = '00.000.000/0001-00'
          props.maxLength = 18
        }
        break
      case 'CAN':
        props.placeholder = '123456789'
        props.maxLength = 9
        break
      case 'CHN':
        props.placeholder = '110101195306153019'
        props.maxLength = 18
        break
      case 'IND':
        if (props.idType === ID_TYPES.VOTER_ID) {
          props.placeholder = 'ABC0000000'
          props.maxLength = 10
        } else if (props.idType === ID_TYPES.DRIVING_LICENSE) {
          props.placeholder = 'HR-0619850034761'
          props.maxLength = 16
        } else if (props.idType === ID_TYPES.TAX_ID) {
          props.placeholder = 'AAAPL1234C'
          props.maxLength = 10
        }
        break
      case 'ITA':
        props.placeholder = 'MSSMRA74D22A001K'
        props.maxLength = 16
        break
      case 'MEX':
        if (props.idType === ID_TYPES.IDENTITY_CARD) {
          props.placeholder = 'HEGG560427MVZRRL05'
          props.maxLength = 18
        } else if (props.idType === ID_TYPES.TAX_ID) {
          props.placeholder = 'GOAP780710RH7'
          props.maxLength = 13
        }
        break
      case 'SGP':
        props.placeholder = 'S9162653E'
        props.maxLength = 9
        break
      case 'ZAF':
        props.placeholder = '9202204720082'
        props.maxLength = 13
        break
      case 'ESP':
        if (props.idType === ID_TYPES.IDENTITY_CARD) {
          props.placeholder = '00000000-A'
          props.maxLength = 10
        } else if (props.idType === ID_TYPES.OTHER) {
          props.placeholder = 'X-0000000-A'
          props.maxLength = 11
        }
        break
      case 'SWE':
        props.placeholder = '19001219-3421'
        props.maxLength = 13
        break
      case 'TUR':
        props.placeholder = '11111111111'
        props.maxLength = 11
        break
    }
  }
  initIdNumberInput()

  return (
    <Input
      {...props}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      type="text"
    />
  )
}

export const ID_NUMBER_COUNTRIES = [
  'ARG',
  'AUS',
  'BRA',
  'CAN',
  'CHN',
  'IND',
  'ITA',
  'MEX',
  'SGP',
  'ZAF',
  'ESP',
  'SWE',
  'TUR',
]

export const hasValidNationalIdValue = (
  country: string,
  idType: string | undefined,
  idValue: string
) => {
  switch (country) {
    case 'ARG':
      return /^\d{8}$/.test(idValue)
    case 'AUS':
      if (idType === ID_TYPES.PASSPORT) {
        return /^[a-zA-Z]{1,2}\d{7}$/.test(idValue)
      } else if (idType === ID_TYPES.DRIVING_LICENSE) {
        return /^[a-zA-Z\d]{1,9}$/.test(idValue)
      }
      break
    case 'BRA':
      if (idType === ID_TYPES.TAX_ID) {
        return /^\d{3}.\d{3}.\d{3}-\d{2}$/.test(idValue)
      } else if (idType === 'other') {
        return /^\d{2}.\d{3}.\d{3}[/]\d{4}-\d{2}$/.test(idValue)
      }
      break
    case 'CAN':
      return /^\d{9}$/.test(idValue)
    case 'CHN':
      return /^\d{17}[a-zA-Z\d]{1}$/.test(idValue)
    case 'IND':
      if (idType === ID_TYPES.VOTER_ID) {
        return /^[a-zA-Z]{3}\d{7}$/.test(idValue)
      } else if (idType === ID_TYPES.DRIVING_LICENSE) {
        return (
          /^[A-Z]{2}-\d{13}$/.test(idValue) ||
          /^[A-Z]{2}\d{2} \d{11}$/.test(idValue)
        )
      } else if (idType === ID_TYPES.TAX_ID) {
        return /^\w{10}$/.test(idValue.toLowerCase())
      }
      break
    case 'ITA':
      return /^[a-zA-Z\d]{16}$/.test(idValue)
    case 'MEX':
      if (idType === ID_TYPES.IDENTITY_CARD) {
        return /^[a-zA-Z\d]{18}$/.test(idValue)
      } else if (idType === ID_TYPES.TAX_ID) {
        return /^[a-zA-Z\d]{13}$/.test(idValue)
      }
      break
    case 'SGP':
      return /^[a-zA-Z]{1}[\d]{7}[a-zA-Z]{1}$/.test(idValue)
    case 'ZAF':
      return /^\d{13}$/.test(idValue)
    case 'ESP':
      if (idType === ID_TYPES.IDENTITY_CARD) {
        return /^\d{8}-[a-zA-Z]{1}$/.test(idValue)
      } else if (idType === ID_TYPES.OTHER) {
        return /^[a-zA-Z]{1}-\d{7}-[a-zA-Z]{1}$/.test(idValue)
      }
      break
    case 'SWE':
      return /^\d{8}-\d{3}$/.test(idValue)
    case 'TUR':
      return /^\d{11}$/.test(idValue)
  }
  return true
}
