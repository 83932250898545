import { TranslateCallback } from '~core/localisation/types'

type IdType = {
  name: string
  type: string
}

const IDENTITY_CARD = 'identity_card'
const PASSPORT = 'passport'
const DRIVING_LICENSE = 'driving_license'
const TAX_ID = 'tax_id'
const VOTER_ID = 'voter_id'
const OTHER = 'other'

export const ALL_ID_TYPES = {
  IDENTITY_CARD,
  PASSPORT,
  DRIVING_LICENSE,
  TAX_ID,
  VOTER_ID,
  OTHER,
}

export const SUPPORTED_COUNTRIES = ['AUS', 'BRA', 'MEX', 'IND', 'ESP']

const allIdTypesListByCountries: { [key: string]: string[] } = {
  AUS: [PASSPORT, DRIVING_LICENSE, OTHER],
  BRA: [TAX_ID, OTHER],
  IND: [VOTER_ID, DRIVING_LICENSE, TAX_ID],
  MEX: [IDENTITY_CARD, TAX_ID],
  ESP: [IDENTITY_CARD, OTHER],
}

const getIdType = (
  country: string,
  idType: string,
  translate: TranslateCallback
) => {
  const idName =
    translate(
      `profile_data.components.id_type_select.${country.toLocaleLowerCase()}_specific.${idType}`
    ) || translate(`profile_data.components.id_type_select.${idType}`)
  return {
    name: idName,
    type: idType,
  }
}

export const getIdTypesList = (
  country: string,
  translate: TranslateCallback
) => {
  const IdTypesList: IdType[] = []

  if (allIdTypesListByCountries[country]) {
    allIdTypesListByCountries[country].forEach((idType) => {
      IdTypesList.push(getIdType(country, idType, translate))
    })
  }

  return IdTypesList
}
